<template>
  <div class="contents">
    <el-form
      ref="form"
      :model="form"
    >
      <el-card>
        <h1>櫻花LINE個人化綁定</h1>
        <h2>請填寫下列完成綁定</h2>
        <hr>
        <el-row>
          <el-col>
            <el-form-item label="請輸入您的姓名">
              <el-input v-model="form.CustName" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="請輸入您的室內電話">
              <el-input v-model="form.CustTel" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="請輸入您的手機號碼">
              <el-input
                v-model="form.CustMobile"
                :readonly="true"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item
              label="縣市"
              class="addressCol"
            >
              <el-select
                v-model="form.city"
                placeholder="通訊地址-縣市"
                @change="form.CustAddrCityId = '';form.CustAddrRoadId = '';"
              >
                <el-option
                  label="請選擇縣市"
                  value=""
                >
                  請選擇縣市
                </el-option>
                <el-option
                  v-for="(item,index) in cityList"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="行政區"
              class="addressCol"
            >
              <el-select
                v-model="form.CustAddrCityId"
                placeholder="通訊地址-行政區"
                @change="setZipRoadList();form.CustAddrRoadId = '';"
              >
                <el-option
                  v-for="(item,index) in showDistOptions()"
                  :key="index"
                  :label="item.zipname"
                  :value="item.hf_zipId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              label="路段"
              class="road"
            >
              <el-select
                v-model="form.CustAddrRoadId"
                placeholder="路段"
              >
                <el-option
                  v-for="(item,index) in zipRoadList"
                  :key="index"
                  :label="item.roadname"
                  :value="item.roadid"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="請輸入地址">
              <el-input v-model="form.CustAddrDetail" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="checkInfoBox">
              <label><input
                v-model="isCheck"
                type="checkbox"
                name="check"
              > <span> 我已閱讀並同意台灣櫻花股份有限公司個人資料聲明</span> </label>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <button
              class="_btnSubmit"
              type="button"
              :disabled="checkFormStatus() || isSending"
              @click="submitMember()"
            >
              送出
            </button>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <!--交成功 -->
    <guide-two
      :is-dialog-show="isShowTwo"
      :no-show-click="noShowClick"
      mody-type="10"
    />
    <!-- 異常 -->
    <guide-two
      :is-dialog-show="isShowTwo3"
      :no-show-click="noShowClick"
      mody-type="11"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import storage from './../storage'
import { post as ajaxPost } from './../plugins/ajax'
import { getZipDist, getZipRoad, getCustomerByLineMid, actionLog } from '../api/api'
import GuideTwo from '@/components/PopUp/Guide_2'
export default {
  components: {
    GuideTwo
  },
  data () {
    return {
      isCheck: false,
      isShowTwo: false,
      isShowTwo3: false,
      isSending: false,
      form: {
        CustName: '',
        CustTel: '',
        CustMobile: '',
        city: '',
        CustAddrCityId: '',
        CustAddrRoadId: '',
        CustAddrDetail: ''
      },
      cityList: [
        '基隆市',
        '臺北市',
        '新北市',
        '桃園市',
        '新竹市',
        '新竹縣',
        '苗栗縣',
        '臺中市',
        '彰化縣',
        '南投縣',
        '雲林縣',
        '嘉義市',
        '嘉義縣',
        '臺南市',
        '高雄市',
        '屏東縣',
        '臺東縣',
        '花蓮縣',
        '宜蘭縣',
        '澎湖縣',
        '金門縣',
        '連江縣'
      ],
      zipDistList: [],
      zipRoadList: [],
      showType: '',
      goToMemberCenter: false
    }
  },
  async mounted () {
    const LINEMid = this.getUserLineId(this.$route) || ''
    const custMobile = storage.getItem('CustMobile')
    if (!custMobile) {
      this.$router.push({ path: '/customerService/memberLinkPhone', query: { mid: LINEMid } })
      return
    }

    this.zipDistList = await this.getDistList()
    const res = _.get(await getCustomerByLineMid({ LINEMid }), 'data.Data')
    if (res) {
      const targetCity = `${res.CustAddress.split('-')[1].split(/縣|市/)[0].replace('台', '臺')}${/市/.test(res.CustAddress) ? '市' : '縣'}`
      this.form.city = targetCity
      this.form.CustName = res.CustName
      this.form.CustTel = res.Tel || res.Mobile
      this.form.CustMobile = res.Mobile
      this.form.CustAddrCityId = res.AddrCityId
      this.form.CustAddrRoadId = res.AddrRoadId
      this.form.CustAddrDetail = res.AddrDetail
      this.setZipRoadList()
    } else {
      this.form.CustMobile = custMobile
    }
  },
  methods: {
    async getDistList () {
      const res = await getZipDist()
      const zipDistList = _.get(res, 'data.Data')
      return zipDistList
    },
    async getZipRoadList (ZipNo) {
      const res = await getZipRoad({ ZipNo })
      const zipRoadList = _.get(res, 'data.Data')
      return zipRoadList
    },
    showDistOptions () {
      return this.zipDistList.filter(o => this.form.city && o.zipname.includes(this.form.city.replace(/臺/, '台'))).map(o => ({ ...o, zipname: `${o.zipno} ${o.zipname.split('-')[1].split(/市|縣/)[1]}` }))
    },
    async setZipRoadList () {
      if (this.form.CustAddrCityId) {
        const hfZipId = this.form.CustAddrCityId
        const selectedDist = this.zipDistList.find(o => o.hf_zipId === hfZipId)
        const zip = selectedDist.zipno
        const list = await this.getZipRoadList(zip)
        this.zipRoadList = list
      }
    },
    async submitMember () {
      this.isSending = true
      const submitData = {
        CustName: this.form.CustName,
        CustMobile: this.form.CustMobile,
        CustTel: this.form.CustTel,
        CustAddrCityId: this.form.CustAddrCityId,
        CustAddrRoadId: this.form.CustAddrRoadId,
        CustAddrVil: '',
        CustAddrDetail: this.form.CustAddrDetail,
        LINEMID: this.getUserLineId(this.$route),
        CustBirthdate: null,
        CustGender: null
      }
      const res = await ajaxPost('https://crmreport.sakura.com.tw/API/CreateCustByLINE', submitData)
      if (res.State) {
        this.showType = 'isShowTwo'
        this.isShowTwo = true
        this.goToMemberCenter = true
        console.log('🚀 ~ Post API: push-coupon-online')
        actionLog({
          userId: submitData.LINEMID,
          action: 'member-register'
        })
        await this.pushOnlineCoupon(submitData.LINEMID)
        // await this.kelakeCoupon(submitData.LINEMID)
      } else if (/LineID已經存在/.test(res.Msg)) {
        this.showType = 'isShowTwo3'
        this.isShowTwo3 = true
        this.goToMemberCenter = true
      } else {
        this.goToMemberCenter = false
        alert(res.Msg)
      }
      this.isSending = false
    },
    noShowClick () {
      switch (this.showType) {
        case 'isShowTwo':
          this.isShowTwo = false
          break

        case 'isShowTwo3':
        default:
          this.isShowTwo3 = false
          break
      }
      if (this.goToMemberCenter) {
        const LINEMid = this.getUserLineId(this.$route) || ''
        this.$router.push({ path: '/customerService/member', query: { mid: LINEMid } })
      }
    },
    checkFormStatus () {
      return !(this.isCheck &&
      this.form.CustName &&
      this.form.CustTel &&
      this.form.CustMobile &&
      this.form.city &&
      this.form.CustAddrCityId &&
      this.form.CustAddrRoadId &&
      this.form.CustAddrDetail)
    },
    // 加入會員 完成綁定流程後 送line point線上優惠券活動
    async pushOnlineCoupon (LINEMID) {
      const couponMsgKey = '3KLHkLBN4CA0iYMoT3tv'
      const submitData = { site: process.env.VUE_APP_SITE, userId: LINEMID, couponMsgKey }
      console.table('submitData:', submitData)
      await ajaxPost('https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/push-coupon-online', submitData)
    }
    // kelake 優惠券活動
    // async kelakeCoupon (LINEMID) {
    //   const submitData = { site: process.env.VUE_APP_SITE, userId: LINEMID }
    //   await ajaxPost('https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/kelake/coupon/push', submitData)
    // }
  }
}
</script>

<style scoped lang="scss">
.addressCol {
  width: 50%;
  display: inline-block;
}

._btnSubmit {
  background: #C8161D;
  box-shadow: 0 2px 4px 0 rgba(35,36,38,0.30);
  border-radius: 4px;
  border: 0px;
  color: white;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

._btnSubmit[disabled] {
  background-color: #c5c5c5;
}

h1 {
    padding: 15px 0;
    font-size: 18px;
    font-weight: 900;
    color: #c8161d;
}

h2 {
    padding: 12px 0;
    font-weight: 900;
    font-size: 16px;
}

h3 {
    padding: 10px 0;
    font-weight: 900;
    font-size: 14px;
}

.contents p {
    padding: 10px 0;
}

.contents {
    line-height: 22px;
}
@import '../assets/styles/mixin.scss';
.contents {
  .el-card {
    border-radius: 1rem;
  }
@media screen and (max-width: 992px) {
  .el-card{
    .el-row{
      .el-col:last-child {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
          span {
            bottom: -2rem;
          }
      }

    }
  }
}
}
</style>
